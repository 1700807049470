import { IconButton, Tooltip } from '@material-ui/core';
import { Facebook, Instagram, MailOutline } from '@material-ui/icons';
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

const ContactIcons = (): JSX.Element => {

  const [copied, setCopied] = useState(false);
  const copyTooltipTxt = copied ? 'Skopiowano!' : 'Kliknij, aby skopiować adres e-mail';

  return (
    <>
      <div className="contact__icon contact__icon--fb">
        <a 
          href="https://www.facebook.com/mysljaknaukowiec/" 
          rel="noopener noreferrer" 
          target="_blank"
        >
          <IconButton><Facebook fontSize="inherit" color="inherit" /></IconButton>
        </a>
        
      </div>
      <div className="contact__icon contact__icon--insta">
        <a 
          href="https://www.instagram.com/mysl_jak_naukowiec" 
          rel="noopener noreferrer" 
          target="_blank"
        >
          <IconButton><Instagram fontSize="inherit" color="inherit" /></IconButton>
        </a>
      </div>
      <div className="contact__icon contact__icon--mail">
        <CopyToClipboard text="kontakt@mysljaknaukowiec.pl" onCopy={(): void => setCopied(true)}>
          <Tooltip title={copyTooltipTxt} >
            <IconButton><MailOutline fontSize="inherit" color="inherit" /></IconButton>
          </Tooltip>
        </CopyToClipboard>
      </div>
    </>
  );
};

export default ContactIcons;