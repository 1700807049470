import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import ContactIcons from 'components/ContactIcons';
import Footer from 'components/Footer';
import Layout from 'components/Layout';
import SEO from 'components/SEO';

const Contact = (): JSX.Element => {
  
  const data = useStaticQuery(graphql`
  query {
    grandpa: contentfulAsset(title: {eq: "Group 306"}) {
      fluid(maxWidth: 420) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
    wave: contentfulAsset(title: {eq: "Path 210"}) {
      fluid(maxWidth: 1920) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
  }
  `);

  return (
    <Layout>
      <SEO title="Kontakt" />
      <div className="contact">
        <div className="contact__content">
          <div className="contact__headers">
            <h1>CHCESZ DOWIEDZIEĆ SIĘ WIĘCEJ?</h1>
            <h2>NAPISZ DO NAS!</h2>    
          </div>
          <div className="contact__img">
            <Img fluid={data.grandpa.fluid} />
            <ContactIcons />
          </div>
        </div>
      </div>
      <Img fluid={data.wave.fluid} className="wave wave--bottom" />
      <Footer />
    </Layout>
  );
};

export default Contact;